import React, { useContext } from 'react';
import { Box, Typography, Avatar, Grid, Card, CardContent, useMediaQuery } from '@mui/material';
import { UserContext } from '../../../context/UserContext';

interface UserProfileProps {
  onUpdateProfile: () => void;
}

const ViewProfilePage: React.FC<UserProfileProps> = ({ onUpdateProfile }) => {
  const { userDetails } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (!userDetails) {
    return <div>Loading...</div>; // Add a loader or a proper loading state
  }

  const shortenAddress = (address: string | null, chars = 4): string => {
    if (!address) return '';
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f8f9fc',
        padding: isMobile ? 2 : 3,  // Adjust padding for mobile
        marginTop: 6,
        marginLeft: isMobile ? 0 : -30,  // Remove negative margin on mobile
        height: 'calc(100vh - 64px)', // Ensures the section takes up full viewport height minus topbar
        overflowY: 'auto', // Makes the section scrollable
        display: 'flex',
        flexDirection: 'column',
        alignItems: isMobile ? 'center' : 'flex-start',  // Center content on mobile
      }}
    >
      {/* Profile Picture */}
      <Avatar
        src="https://i.pravatar.cc/300"
        alt="Profile Picture"
        sx={{
          width: isMobile ? 100 : 150,  // Smaller avatar on mobile
          height: isMobile ? 100 : 150,  // Smaller avatar on mobile
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
          marginBottom: 3,
        }}
      />

      {/* Profile Details */}
      <Card
        sx={{
          width: '100%',
          maxWidth: 600,
          marginBottom: 3,
          padding: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          textAlign: isMobile ? 'center' : 'left',  // Center text on mobile
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Email:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {userDetails.email}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Password:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                ********
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Wallet Address:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {shortenAddress(userDetails.wallet_address)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Token Balance:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {userDetails.token_balance} NBITAI
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Purchase At:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                INR 5.00
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Purchase Date:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {userDetails.created_at}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ViewProfilePage;