import React, { useContext, useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Typography,
  IconButton,
  useMediaQuery
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import AuthenticationIcon from '@mui/icons-material/Lock';
import TypographyIcon from '@mui/icons-material/TextFields';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LayersIcon from '@mui/icons-material/Layers';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StorageIcon from '@mui/icons-material/Storage';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Logo from '../../assets/images/nbitlogo.png';
import { useNavigate } from 'react-router-dom';
import Toastify from 'toastify-js';
import { UserContext } from '../../context/UserContext';
import { disconnectWallet } from '../../web3config';
import Web3Context from '../../context/Web3Context';

type SidebarProps = {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
};

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, toggleSidebar, activePage, setActivePage }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const drawerWidth = isMobile ? 200 : 240;
  const { presalesDetails } = useContext(UserContext);
  const { disconnectWallet } = useContext(Web3Context); // Access the disconnectWallet function


  const formatNumber = (number: number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`; // Format in millions
    }
    return number.toLocaleString(); // Format as regular number with commas
  };
  
  // Ensure the number doesn't go below 0
  const getBoughtTokens = (totalAvailable: number, totalStart: number) => {
    const boughtTokens = totalStart - totalAvailable;
    return boughtTokens > 0 ? boughtTokens : 0; // Prevent negative values
  };
  
  // Example values: Replace these with real data from presalesDetails
  const totalStartTokens = 20000000; // Total tokens at the start (20M)
  const totalAvailableTokens = presalesDetails?.Total_avl_tokens || 0; // Available tokens from API
  
  // Calculate bought tokens
  const boughtTokens = getBoughtTokens(totalAvailableTokens, totalStartTokens);
  

  const handleLogout = async () => {
    // Clear local storage
    localStorage.removeItem('jwt');
    localStorage.removeItem('user_email');
    localStorage.clear(); // In case there are other keys related to user session
    
    
    try {
      // Call the disconnectWallet function from Web3Context to disconnect the wallet
      await disconnectWallet();
      console.log("Wallet disconnected.");
    } catch (error) {
      console.error('Failed to disconnect wallet:', error);
    }
    // Navigate to login page
    navigate('/login');

    Toastify({
      text: "Logged Out Successfully",
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    }).showToast();
  };

  const getButtonStyle = (page: string) => ({
    color: activePage === page ? '#3f51b5' : '#4F4F4F',
    padding: '8px 16px',
    borderRadius: '8px',
    margin: '4px 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',  // Light background on hover
    },
    backgroundColor: activePage === page ? '#f0f0f5' : 'transparent',  // Light blue for active items
  });

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      anchor="left"
      open={isSidebarOpen}
      onClose={toggleSidebar}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >


      {/* Logo Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px' }}>
        <img src= {Logo} alt="Logo" style={{ width: '40px', marginRight: '8px' }} />
        <IconButton onClick={toggleSidebar} sx={{ marginLeft: 'auto' }}>  {/* This will push the hamburger icon to the right */}
          <MenuIcon />
        </IconButton>
      </Box>

      <Divider />

      {/* Dashboard Section */}
      <List>
        <ListItem
          onClick={() => setActivePage('Dashboard')}
          sx={getButtonStyle('Dashboard')}
        >
          <ListItemIcon>
            <DashboardIcon sx={{ color: activePage === 'Dashboard' ? '#3f51b5' : '#4F4F4F' }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </List>

      <Divider sx={{ my: 2 }} />

      {/* Pages Section */}
{/* Pages Section - Purchase Token */}
<Typography variant="h6" sx={{ padding: '16px', color: '#7E7E7E', fontSize: '0.875rem', fontWeight: 'bold' }}>
  Purchase Nbit Token
</Typography>

<List>
  <ListItem
    onClick={() => setActivePage('Purchase')}
    sx={getButtonStyle('Purchase')}
  >
    <ListItemIcon>
      <ShoppingBagIcon sx={{ color: activePage === 'PreSale' ? '#3f51b5' : '#4F4F4F' }} />
    </ListItemIcon>
    <ListItemText primary="Purchase NBITAI" />
  </ListItem>

  <ListItem
    onClick={() => setActivePage('AboutToken')}
    sx={getButtonStyle('AboutToken')}
  >
    <ListItemIcon>
      <HelpOutlineIcon sx={{ color: activePage === 'AboutToken' ? '#3f51b5' : '#4F4F4F' }} />
    </ListItemIcon>
    <ListItemText primary="Download Docs" />
  </ListItem>
</List>

<Divider sx={{ my: 2 }} />

{/* User Profile Section */}
<Typography variant="h6" sx={{ padding: '16px', color: '#7E7E7E', fontSize: '0.875rem', fontWeight: 'bold' }}>
  User Profile
</Typography>

<List>
  <ListItem
    onClick={() => setActivePage('ViewProfile')}
    sx={getButtonStyle('ViewProfile')}
  >
    <ListItemIcon>
      <PersonIcon sx={{ color: activePage === 'ViewProfile' ? '#3f51b5' : '#4F4F4F' }} />
    </ListItemIcon>
    <ListItemText primary="View Profile" />
  </ListItem>

  {/* <ListItem
    onClick={() => setActivePage('UpdateProfile')}
    sx={getButtonStyle('UpdateProfile')}
  >
    <ListItemIcon>
      <PersonIcon sx={{ color: activePage === 'UpdateProfile' ? '#3f51b5' : '#4F4F4F' }} />
    </ListItemIcon>
    <ListItemText primary="Update Profile" />
  </ListItem> */}
</List>

<Divider sx={{ my: 2 }} />

{/* // Progress Section */}
<Box sx={{ padding: '16px' }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Typography variant="body2">Token Purchased</Typography>
    
    {/* Display bought tokens and total available tokens with 'M' for millions */}
    <Typography variant="body2">
      {formatNumber(boughtTokens)}/{formatNumber(totalStartTokens)}
    </Typography>
  </Box>

  {/* Progress bar */}
  <Box sx={{ backgroundColor: '#E0E0E0', borderRadius: '5px', marginTop: '8px' }}>
    <Box 
      sx={{ 
        width: `${(boughtTokens / totalStartTokens) * 100}%`, 
        backgroundColor: '#3f51b5', 
        height: '8px', 
        borderRadius: '5px' 
      }} 
    />
  </Box>

  {/* Display percentage */}
  <Typography sx={{ marginTop: '8px', textAlign: 'right', fontSize: '0.85rem', color: '#7E7E7E' }}>
    {(boughtTokens / totalStartTokens * 100).toFixed(2)}% of tokens sold
  </Typography>
</Box>

<Divider sx={{ my: 2 }} />

{/* Logout Button */}
<Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
  <ListItem
    onClick={handleLogout}
    sx={{ color: '#d32f2f', paddingLeft: '16px', marginBottom: '16px' }}
  >
    <ListItemIcon>
      <ExitToAppIcon sx={{ color: '#d32f2f' }} />
    </ListItemIcon>
    <ListItemText primary="Logout" />
  </ListItem>
</Box>


    </Drawer>
  );
};

export default Sidebar;