import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Toastify from 'toastify-js';
import { useNavigate } from 'react-router-dom';
import './OtpModal.css'; // CSS for styling the modal

interface OTPModalProps {
  email: string;
  otpCode: string;
  setOtpCode: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => Promise<void>;
  setShowOTPModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OTPModal: React.FC<OTPModalProps> = ({ email, otpCode, setOtpCode, onSubmit, setShowOTPModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds
  const navigate = useNavigate();

  // Countdown timer logic
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    // Close the modal when countdown reaches 0
    if (countdown === 0) {
      handleClose();
    }

    return () => clearInterval(timer);
  }, [countdown]);

  // Format the remaining time
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Close the modal
  const handleClose = () => {
    setShowOTPModal(false);
  };

  // Handle OTP verification
  const handleVerifyOTP = async () => {
    if (!otpCode) {
      showErrorMessage("Please enter the OTP.");
      return;
    }
    setIsLoading(true);
    try {
      await onSubmit(); // Using the parent component's submit handler
    } catch (error) {
      showErrorMessage("OTP verification failed.");
    } finally {
      setIsLoading(false);
    }
  };

  // Show success message
  const showSuccessMessage = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    }).showToast();
  };

  // Show error message
  const showErrorMessage = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
    }).showToast();
  };

  return (
    <div className="otp-modal-overlay">
      <div className="otp-modal-content">
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        <h2>Verify OTP</h2>
        <p>A one-time password has been sent to your email: {email}</p>
        <p>Enter the OTP below to complete your registration.</p>
        <div className="input-group">
          <label htmlFor="otp">Enter OTP</label>
          <input
            type="text"
            id="otp"
            placeholder="Enter OTP"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            required
          />
        </div>
        <p className="countdown">Time remaining: {formatTime(countdown)}</p>
        <button onClick={handleVerifyOTP} className="verify-button" disabled={isLoading}>
          {isLoading ? 'Verifying...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default OTPModal;