import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button,useMediaQuery } from '@mui/material';
import TronWeb from 'tronweb';
import Web3Context  from '../../context/Web3Context';
import { modal } from '../../web3config';
import BannerImage from '../../assets/images/nbitai.io.png';
import BannerImage1 from '../../assets/images/nbit2.png';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';

// Extend window to include tronWeb and ethereum
declare global {
  interface Window {
    ethereum?: any;
    tronWeb?: any;
  }
}

interface MiddleSectionProps {
  show: boolean;
  handleClose: () => void;
}

const TOKEN_PRICE_INR = 5; // Token price is INR 5
const USDT_ERC20_CONTRACT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; // ERC20 USDT contract on Ethereum

const MiddleSection: React.FC<MiddleSectionProps> = ({ show, handleClose }) => {
  const { account, isConnected, connectWallet, disconnectWallet, currentNetwork, walletProvider, web3 } = useContext(Web3Context);
  const [amount, setAmount] = useState<number | null>(null); // State to track user input amount
  const [tokens, setTokens] = useState<number>(0); // State to store the equivalent number of tokens
  const [isLoading, setIsLoading] = useState<boolean>(false); // State to handle loading state
  const isMobile = useMediaQuery('(max-width: 768px)'); // Detect mobile screen
  const { presalesDetails } = useContext(UserContext);


  // Detect Mobile Utility
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(e.target.value);
    
    // Validate if the input value is within the allowed range
    if (inputValue < 10) {
      Toastify({
        text: 'Minimum purchase is $10.',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
      setAmount(10); // Set minimum amount to $10
      setTokens(10 / TOKEN_PRICE_INR); // Update token amount accordingly
    } else if (inputValue > (presalesDetails?.Total_avl_tokens || 0) * TOKEN_PRICE_INR) {
      Toastify({
        text: 'Amount exceeds total available tokens.',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
      setAmount((presalesDetails?.Total_avl_tokens || 0) * TOKEN_PRICE_INR); // Cap amount at the maximum allowed based on available tokens
      setTokens(presalesDetails?.Total_avl_tokens || 0); // Cap token amount
    } else {
      setAmount(inputValue);
      setTokens(inputValue / TOKEN_PRICE_INR); // Calculate tokens based on valid input
    }
  };

  // const checkNetwork = async () => {
  //   if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
  //     const currentNetwork = await window.tronWeb.fullNode.host;
  //     if (!currentNetwork.includes('api.trongrid.io')) {
  //       alert('Please switch to the Tron network in your wallet.');
  //     } else {
  //       console.log("Connected to Tron Network.");
  //     }
  //   } else {
  //     console.error("Tron Wallet not connected. Please connect via TronLink.");
  //   }
  // };

  // useEffect(() => {
  //   if (isConnected) {
  //     checkNetwork(); // Check network once wallet is connected
  //   }
  // }, [isConnected]);


  const handleConnect1 = async () => {
    if (isConnected) {
      // Disconnect Wallet
      await disconnectWallet();
      Toastify({
        text: 'Disconnected from wallet',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
    } else {
      // Connect Wallet
      try {
        await modal.open(); // Open WalletConnect Modal
        console.log('Attempting to connect wallet...');
        const web3Instance = await connectWallet();
        if (!web3Instance) {
          Toastify({
            text: 'Failed to connect wallet. Please try again.',
            duration: 3000,
            gravity: 'top',
            position: 'center',
            backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
          }).showToast();
        }
      } catch (error) {
        console.error('Error connecting wallet:', error);
        Toastify({
          text: 'Error occurred. Please try again later.',
          duration: 3000,
          gravity: 'top',
          position: 'center',
          backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
        }).showToast();
      }
    }
  };


  // Blockchain transfer function
  const transferUSDT = async (recipientAddress: string, amount: number) => {
    try {
      if (web3) {
        if (!account) {
          throw new Error("No account is connected.");
        }

        const usdtContract = new web3.eth.Contract(
          [
            {
              constant: false,
              inputs: [
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' },
              ],
              name: 'transfer',
              outputs: [{ name: '', type: 'bool' }],
              type: 'function',
            },
          ],
          USDT_ERC20_CONTRACT_ADDRESS
        );

        const amountInWei = web3.utils.toWei(amount.toString(), 'mwei'); // Convert USDT amount (6 decimals)

        const transaction = await usdtContract.methods
          .transfer(recipientAddress, amountInWei)
          .send({ from: account || undefined });

        console.log('Transaction successful:', transaction);
        return true; // Transfer successful
      } else {
        Toastify({
          text: 'Please connect to the Ethereum network to transfer USDT.',
          duration: 3000,
          gravity: 'top',
          position: 'center',
          backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
        }).showToast();
        return false;
      }
    } catch (error) {
      console.error('USDT Transfer failed:', error);
      Toastify({
        text: 'Transfer failed. Please try again.',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
      return false;
    }
  };

  // Handle token purchase logic
  const handlePurchaseToken = async () => {
    if (!isConnected) {
      Toastify({
        text: 'Please connect your wallet first.',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
      return;
    }

    setIsLoading(true);
    try {
      const transferSuccess = await transferUSDT("0x7a87D10C404c7d7Dd360733eD13775d1554C0c39", tokens); // Replace with actual recipient address

      if (transferSuccess) {
        // After successful blockchain transfer, call the API
        const response = await axios.post('https://nbitai.gamingpandastudios.com/apisnbit/nbitapis/operations/tokenPurchase.php', {
          token: localStorage.getItem('jwt'), // Pass JWT token stored in localStorage
          amount_paid: amount?.toString(),
        });

        if (response.data.message === 'Token purchase successful') {
          Toastify({
            text: `Purchase successful! You bought ${response.data.token_amount} NBITAI for INR ${response.data.amount_paid}`,
            duration: 3000,
            gravity: 'top',
            position: 'center',
            backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
          }).showToast();
        } else {
          Toastify({
            text: 'Purchase failed. Please try again.',
            duration: 3000,
            gravity: 'top',
            position: 'center',
            backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
          }).showToast();
        }
      }
    } catch (error) {
      console.error('Token purchase error:', error);
      Toastify({
        text: 'Network error. Please try again later.',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#f8f9fc', padding: 3, marginTop: 6, marginLeft: isMobile ? 0 : -30, height: 'calc(100vh - 64px)', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>

      {/* Two Banner Boxes */}
      <Grid container spacing={isMobile ? 1 : 3} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} md={6}>
        <Box sx={{ backgroundImage: `url(${BannerImage})`, backgroundSize: 'cover', backgroundPosition: 'center', height: isMobile ? '150px' : '200px', borderRadius: '15px', backgroundRepeat: 'no-repeat' }} />
        </Grid>
        <Grid item xs={12} md={6}>
        <Box sx={{ backgroundImage: `url(${BannerImage1})`, backgroundSize: 'cover', backgroundPosition: 'center', height: isMobile ? '150px' : '200px', borderRadius: '15px', backgroundRepeat: 'no-repeat' }} />
        </Grid>
      </Grid>

      {/* Purchase Token Section */}
      <Box sx={{ textAlign: 'center', padding: 3 }}>
      <Typography 
      variant={isMobile ? 'h6' : 'h5'} sx={{ marginBottom: 2, fontWeight: 'bold', color: '#1976d2' }}>Purchase NBITAI Token
      </Typography>


        {/* Input box for user to enter amount */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="body1" sx={{ marginBottom: 1, color: '#7E7E7E' }}>
            Enter Amount
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <input
              type="number"
              value={amount || ''}
              onChange={handleAmountChange}
              placeholder="Enter amount"
              style={{
                padding: '10px',
                fontSize: '1.2rem',
                border: '2px solid #1976d2',
                borderRadius: '10px',
                outline: 'none',
                width: '250px',
                marginRight: '10px',
                textAlign: 'center',
              }}
            />
          </Box>
          <Typography sx={{ marginTop: 1, color: '#7E7E7E', fontSize: '0.9rem' }}>
            You will get: {tokens.toFixed(2)} NBITAI
          </Typography>
        </Box>

        {/* Buttons */}
        <Box sx={{ textAlign: 'center' }}>
          {/* Connect Wallet Button */}
          <Button
            variant="contained"
            sx={{
              marginRight: 2,
              backgroundColor: isConnected ? '#43a047' : '#1976d2',
              color: '#fff',
              borderRadius: '30px',
              padding: '10px 20px',
              fontSize: '1rem',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
            }}
            onClick={() => {
              if (isConnected) {
                Toastify({
                  text: 'You can now purchase your tokens',
                  duration: 3000,
                  gravity: 'top',
                  position: 'center',
                  backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
                }).showToast();
              } else {
                handleConnect1();
              }
            }}
          >
            {isConnected ? `Connected: ${account?.slice(0, 6)}...${account?.slice(-4)}` : 'Connect Wallet'}
          </Button>
           
          {/* Purchase Token Button */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: isConnected ? '#ff9800' : '#D3D3D3', // Disable if not connected
              color: '#fff',
              borderRadius: '30px',
              padding: '10px 20px',
              fontSize: '1rem',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
            }}
            onClick={handlePurchaseToken}
            disabled={!isConnected || isLoading} // Disable if wallet not connected or loading
          >
            {isLoading ? 'Processing...' : 'Purchase Token'}
          </Button>
        </Box>
      </Box>

      {/* Grid container for the three cards */}
      <Grid container spacing={isMobile ? 2 : 3} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              backgroundColor: '#4062bb',
              backgroundImage: 'linear-gradient(316deg, #4062bb 0%, #5200ae 74%)',
              color: '#fff',
              height: 150,
              backdropFilter: 'blur(10px)',
              borderRadius: '15px',
              boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
            }}
          >
            <CardContent>
              <Typography variant="h5">0.00</Typography>
              <Typography>NBITAI Token Purchased</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              backgroundColor: '#05e8ba',
              backgroundImage: 'linear-gradient(315deg, #05e8ba 0%, #087ee1 74%)',
              color: '#fff',
              height: 150,
              backdropFilter: 'blur(10px)',
              borderRadius: '15px',
              boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
            }}
          >
            <CardContent>
              <Typography variant="h5">INR 5.00</Typography>
              <Typography>NBITAI Token Price</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              backgroundColor: '#f5df2e',
              backgroundImage: 'linear-gradient(315deg, #f5df2e 0%, #f07654 74%)',
              color: '#fff',
              height: 150,
              backdropFilter: 'blur(10px)',
              borderRadius: '15px',
              boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
            }}
          >
            <CardContent>
              <Typography variant="h5">{presalesDetails?.Total_avl_tokens || 0}</Typography>
              <Typography>Total Available NBITAI tokens</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>




    </Box>
  );
};

export default MiddleSection;