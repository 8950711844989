import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';
import logowhite from '../../assets/images/nbitwhitelogo.png';
const LoadingScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay (e.g., 2 seconds) or until content is fully loaded
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Return null when loading is false to remove the loading screen
  if (!loading) {
    return null;
  }

  return (
    <div id="loading-screen">
      <img src={logowhite} id="loading-logo" alt="Logo" />
      <div id="loading-bar-container">
        <div id="loading-bar"></div>
      </div>
      <p id="loading-text">Loading...</p>
    </div>
  );
};

export default LoadingScreen;