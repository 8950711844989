import React, { createContext, useState, useEffect } from 'react';
import Web3 from 'web3';
import { connectWallet, disconnectWallet, getConnectedAddress } from '../web3config';

interface Web3ContextType {
  web3: Web3 | null;
  account: string | null;
  isConnected: boolean;
  currentNetwork: string | null;
  walletProvider: string | null;
  connectWallet: () => Promise<Web3 | null>;
  disconnectWallet: () => void;
}

const Web3Context = createContext<Web3ContextType>({
  web3: null,
  account: null,
  isConnected: false,
  currentNetwork: null,
  walletProvider: null,
  connectWallet: async () => null,
  disconnectWallet: () => {},
});

export const Web3Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [currentNetwork, setCurrentNetwork] = useState<string | null>(null);
  const [walletProvider, setWalletProvider] = useState<string | null>(null);
  const [web3Initialized, setWeb3Initialized] = useState<boolean>(false); // State for initialization

  
  const handleConnectWallet = async (): Promise<Web3 | null> => {
    try {
      const provider = await connectWallet();
      if (provider) {
        const web3Instance = new Web3(provider);
        const address = await getConnectedAddress(provider);

        // if (window.ethereum && window.ethereum.isMetaMask) {
        //   setWalletProvider('MetaMask');
        // } else if (window.ethereum && window.ethereum.isTrust) {
        //   setWalletProvider('Trust Wallet');
        // } else if (window.tronWeb) {
        //   setWalletProvider('TronLink');
        // }

        const chainId = await web3Instance.eth.getChainId();
        const networkName = Number(chainId) === 1 ? 'eth' : Number(chainId) === 56 ? 'bsc' : 'unknown';        setCurrentNetwork(networkName);

        if (address) {
          setWeb3(web3Instance);
          setAccount(address);
          setIsConnected(true);
          localStorage.setItem('connectedAccount', address);
          return web3Instance;
        }
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
    return null;
  };



  const handleDisconnectWallet = async () => {
    try {
      await disconnectWallet();
      setWeb3(null);
      setAccount(null);
      setIsConnected(false);
      setCurrentNetwork(null);
      setWalletProvider(null);
      localStorage.removeItem('connectedAccount');
      console.log("Wallet disconnected.");
    } catch (error) {
      console.error('Failed to disconnect wallet:', error);
    }
  };

  useEffect(() => {
    const checkConnectedWallet = async () => {
      const connectedAccount = localStorage.getItem('connectedAccount');
      if (connectedAccount) {
        const provider = await connectWallet();
        if (provider) {
          const web3Instance = new Web3(provider);
          setWeb3(web3Instance);
          setAccount(connectedAccount);
          setIsConnected(true);
          const chainId = await Number(web3Instance.eth.getChainId());
          const networkName = chainId === 1 ? 'eth' : chainId === 56 ? 'bsc' : 'unknown';
          setCurrentNetwork(networkName);

          if (window.ethereum && window.ethereum.isMetaMask) {
            setWalletProvider('MetaMask');
          } else if (window.ethereum && window.ethereum.isTrust) {
            setWalletProvider('Trust Wallet');
          } else if (window.tronWeb) {
            setWalletProvider('TronLink');
          }
        }
      }
    };

    checkConnectedWallet();
  }, []);

  return (
    <Web3Context.Provider
      value={{
        web3,
        account,
        isConnected,
        currentNetwork,
        walletProvider,
        connectWallet: handleConnectWallet,
        disconnectWallet: handleDisconnectWallet,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export default Web3Context;