import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import './LoginPage.css';
import { BeatLoader } from 'react-spinners';
import { UserContext } from '../../../context/UserContext';
import Web3Context from '../../../context/Web3Context';
import { modal } from '../../../web3config';
import { useNavigate, Link } from 'react-router-dom';
import loginImage from '../../../assets/images/girl.png';

const LoginPage: React.FC = () => {
  const { connectWallet, disconnectWallet, account, isConnected } = useContext(Web3Context);
  const { setUserDetails } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Login with wallet logic
  useEffect(() => {
    const loginWithWallet = async () => {
      if (isConnected && account) {
        setIsLoading(true);
        try {
          const response = await axios.post('https://nbitai.gamingpandastudios.com/apisnbit/nbitapis/auth/loginUser.php', {
            wallet_address: account,
          });

          if (response.data.message === 'Login successful') {
            localStorage.setItem('jwt', response.data.jwt);
            setUserDetails(response.data.user_details); // Set user details in context
            Toastify({
              text: "Logged in successfully!",
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: "top",
              position: "center",
              backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
              stopOnFocus: true,
            }).showToast();

            setTimeout(() => {
              navigate('/dashboard');
            }, 3000);
          } else {
            showErrorMessage("Login failed. Please check your credentials.");
          }
        } catch (error) {
          showErrorMessage("Network Error. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    loginWithWallet();
  }, [isConnected, account, setUserDetails, navigate]);

  // Shorten wallet address
  const shortenAddress = (address: string | null, chars = 4): string => {
    if (!address) return '';
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  // Handle email/password login
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://nbitai.gamingpandastudios.com/apisnbit/nbitapis/auth/loginUser.php', {
        email,
        password,
      });

      if (response.data.message === 'Login successful') {
        localStorage.setItem('jwt', response.data.jwt);
        setUserDetails(response.data.user_details); // Set user details in context
        Toastify({
          text: "Logged in successfully!",
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "center",
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true,
        }).showToast();

        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      } else {
        showErrorMessage(response.data.message || "Login failed.");
      }
    } catch (error) {
      showErrorMessage("Login failed");
    } finally {
      setIsLoading(false);
    }
  };

  // Show error messages
  const showErrorMessage = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top",
      position: "center",
      backgroundColor: "linear-gradient(to right, #FF0000, #CB4335)",
      stopOnFocus: true,
    }).showToast();
  };

  // Handle wallet connect
  const handleConnect1 = async () => {
    if (isConnected) {
      await disconnectWallet();
    } else {
      try {
        modal.open().then(async () => {
          await connectWallet();
        });
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    }
  };

  return (
    <div className="login-page">
      <div className="web3-section">
        <h1>Login with wallet authorization</h1>
        <img src={loginImage} alt="Web3 Wallet" className="web3-image" />
        <button className="web3-button" onClick={handleConnect1}>
          {isConnected ? `Connected: ${shortenAddress(account)}` : 'Enter the Web3 world'}
        </button>
        <p>Quick registration into your NbitAI Account</p>
        <p>Manage your account with one single wallet address or email</p>
      </div>

      <div className="login-form-section">
        <h2>NbitAi Login</h2>
        <p>Welcome back! Log In with your Email or Phone number</p>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Please enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button" disabled={isLoading}>
            {isLoading ? <BeatLoader size={8} color={"#ffffff"} /> : 'Log In'}
          </button>
        </form>
        {/* Register Now Link */}
        <div className="register-now-section">
          <span>Don't have an account? </span>
          <Link to="/register" className="register-link">Register Now</Link> {/* Link to /register */}
        </div>
        <div className="wallet-connect-section">
          <h3>Login with Wallet Connect</h3>
          <button className="wallet-connect-button" onClick={handleConnect1}>
            {isConnected ? `Connected: ${shortenAddress(account)}` : 'Connect Wallet'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;