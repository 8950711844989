// src/web3Config.ts
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { mainnet, bsc } from 'viem/chains'; 
import Web3 from 'web3';

const projectId = 'd2c0b9cadd2c4df0916239634e9dee5c';

const metadata = {
  name: 'NbitAI Web',
  description: 'A Staking Platform for users',
  url: 'https://nbitai.com/',
  icons: ['https://access.nbitai.com/static/media/nbitwhitelogo.da2ea728ad74cb353373.png'],
};

const chains = [mainnet, bsc] as const; 

export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

export const modal = createWeb3Modal({
  wagmiConfig: config,
  projectId,
});

export const connectWallet = async () => {

  const { connectors } = config;
  const connector = connectors[0]; // Assuming first connector is used
  await connector.connect();
  const provider = (await connector.getProvider()) as any; // Type assertion for provider
  return provider;

  // if (typeof window.ethereum !== 'undefined') {
  //   // MetaMask is installed on the browser
  //   try {
  //     const web3 = new Web3(window.ethereum);
  //     const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

  //     // Listen to account and chain changes
  //     window.ethereum.on('accountsChanged', (accounts: string[]) => {
  //       console.log('Accounts changed:', accounts);
  //       if (accounts.length === 0) {
  //         disconnectWallet();
  //       } else {
  //         localStorage.setItem('connectedAccount', accounts[0]);
  //         window.location.reload(); // or update state accordingly
  //       }
  //     });

  //     window.ethereum.on('chainChanged', (chainId: number) => {
  //       console.log('Chain changed:', chainId);
  //       window.location.reload(); // Reload DApp on network change
  //     });

  //     localStorage.setItem('connectedAccount', accounts[0]); // Save connected account
  //     return web3;
  //   } catch (error) {
  //     console.error("Failed to connect to MetaMask:", error);
  //     return null;
  //   }
  // } else {
  //   // Use WalletConnect for mobile or if no browser wallet is detected
  //   try {
  //     const { connectors } = config;
  //     const connector = connectors[0]; // Assuming the first connector is used
  //     await connector.connect();
  //     const provider = (await connector.getProvider()) as any;

  //     // Listen to account and chain changes
  //     provider.on('accountsChanged', (accounts: string[]) => {
  //       console.log('accountsChanged:', accounts);
  //       if (accounts.length === 0) {
  //         disconnectWallet();
  //       } else {
  //         localStorage.setItem('connectedAccount', accounts[0]);
  //         window.location.reload(); // or update state accordingly
  //       }
  //     });

  //     provider.on('chainChanged', (chainId: number) => {
  //       console.log('chainChanged:', chainId);
  //       window.location.reload(); // Reload the DApp to handle network change
  //     });

  //     return provider;
  //   } catch (error) {
  //     console.error("Failed to connect via WalletConnect:", error);
  //     return null;
  //   }
  // }
};

export const getConnectedAddress = async (provider: any) => {
  const accounts = await provider.request({ method: 'eth_accounts' });
  console.log(accounts);
  return accounts[0]; // Returning the first account
};

export const disconnectWallet = async () => {
  const { connectors } = config;
  const connector = connectors[0]; // Assuming first connector is used
  const provider = (await connector.getProvider()) as any; // Type assertion for provider
  if (provider.disconnect) {
    await provider.disconnect();
  }
};