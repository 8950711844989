import React from 'react';
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import WhitePaperPDF from '../../../assets/Whitepaper.pdf'; // Make sure to replace these paths with your actual files
import TokenomicsPDF from '../../../assets/Tokenomics.pdf';

const AboutTokenPage: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 768px)'); // Detect if it's mobile

  return (
    <Box
      sx={{
        backgroundColor: '#f8f9fc',
        padding: isMobile ? 2 : 3, // Adjust padding for mobile
        marginTop: 6,
        marginLeft: isMobile ? 0 : -30, // Remove negative margin for mobile
        height: 'calc(100vh - 64px)', // Ensures the section takes up full viewport height minus topbar
        overflowY: 'auto', // Makes the section scrollable
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center content on all screen sizes
      }}
    >
      {/* Page Title */}
      <Typography
        variant={isMobile ? 'h5' : 'h4'} // Smaller font for mobile
        sx={{
          marginBottom: 4,
          fontWeight: 'bold',
          color: '#1976d2',
          textAlign: 'center',
        }}
      >
        Download our Whitepaper and Tokenomics
      </Typography>

      {/* Container for the two download boxes */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row', // Stack boxes vertically on mobile
          gap: '20px', // Spacing between the boxes
          width: isMobile ? '90%' : 'auto', // Take up more width on mobile
          justifyContent: 'center', // Center content on all screen sizes
        }}
      >
        {/* Whitepaper Box */}
        <Box
          sx={{
            width: isMobile ? '100%' : '300px', // Full width on mobile, larger on desktop
            height: '200px',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition
            '&:hover': {
              transform: 'scale(1.05)', // Slightly scale up on hover
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', // Stronger shadow on hover
            },
            position: 'relative', // Needed to position the download icon
          }}
          onClick={() => window.open(WhitePaperPDF, '_blank')}
        >
          <Typography variant="h6" sx={{ color: '#1976d2', textAlign: 'center' }}>
            Whitepaper
          </Typography>
          <IconButton
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Box>

        {/* Tokenomics Box */}
        <Box
          sx={{
            width: isMobile ? '100%' : '300px', // Full width on mobile, larger on desktop
            height: '200px',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition
            '&:hover': {
              transform: 'scale(1.05)', // Slightly scale up on hover
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', // Stronger shadow on hover
            },
            position: 'relative', // Needed to position the download icon
          }}
          onClick={() => window.open(TokenomicsPDF, '_blank')}
        >
          <Typography variant="h6" sx={{ color: '#1976d2', textAlign: 'center' }}>
            Tokenomics
          </Typography>
          <IconButton
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutTokenPage;