import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Avatar, Box, Button, Typography, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/images/nbitlogo.png';
import Web3Context from '../../context/Web3Context'; // Assuming you have a Web3 context set up
import Toastify from 'toastify-js';
import { modal } from '../../web3config';

const USDT_ERC20_CONTRACT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; // ERC20 USDT contract address
const USDT_ABI = [
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
];

const Topbar: React.FC<{ toggleSidebar: () => void }> = ({ toggleSidebar }) => {
  const { isConnected, account, connectWallet, disconnectWallet, web3 } = useContext(Web3Context);
  const [usdtBalance, setUsdtBalance] = useState<string | null>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const shortenAddress = (address: string | null, chars = 4): string => {
    if (!address) return '';
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  // Function to fetch the USDT balance of the connected wallet
  const fetchUSDTBalance = async () => {
    try {
      if (web3 && isConnected && account) {
        const usdtContract = new web3.eth.Contract(USDT_ABI, USDT_ERC20_CONTRACT_ADDRESS);
        const balance = await usdtContract.methods.balanceOf(account).call();

        if (balance && typeof balance === 'string') {
          const balanceInEther = web3.utils.fromWei(balance, 'mwei'); // Convert from smallest unit to USDT value
          setUsdtBalance(balanceInEther);
        } else {
          setUsdtBalance('0'); // Default to '0' if the balance is not a valid value
        }
      } else {
        setUsdtBalance(null); // Set null if wallet is not connected or web3 is not available
      }
    } catch (error) {
      console.error('Error fetching USDT balance:', error);
      setUsdtBalance('0'); // In case of error, set balance to '0'
    }
  };

  const checkNetwork = async () => {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      const currentNetwork = await window.tronWeb.fullNode.host;
      if (!currentNetwork.includes('api.trongrid.io')) {
        alert('Please switch to the Tron network in your wallet.');
      } else {
        console.log("Connected to Tron Network.");
      }
    } else {
      console.error("Tron Wallet not connected. Please connect via TronLink.");
    }
  };

  useEffect(() => {
    if (isConnected && account) {
      fetchUSDTBalance();
      Toastify({
        text: 'Wallet connected successfully!',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
      }).showToast();
      checkNetwork(); // Check network once wallet is connected
    }
  }, [isConnected, account]);

  const handleConnect1 = async () => {
    if (isConnected) {
      await disconnectWallet();
      Toastify({
        text: 'Disconnected from wallet',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
    } else {
      try {
        await modal.open(); 
        console.log('Attempting to connect wallet...');
        try {
          const web3Instance = await connectWallet();
          if (!web3Instance) {
            Toastify({
              text: 'Failed to connect wallet. Please try again.',
              duration: 3000,
              gravity: 'top',
              position: 'center',
              backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
            }).showToast();
          }
        } catch (error) {
          console.error('Error connecting wallet:', error);
          Toastify({
            text: 'Error occurred. Please check the console for more details.',
            duration: 3000,
            gravity: 'top',
            position: 'center',
            backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
          }).showToast();
        }// Always open the WalletConnect modal for connection
      } catch (error) {
        console.error('Error connecting via WalletConnect modal:', error);
      }
    }
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#ffffff', color: '#000', boxShadow: 'none', zIndex: 1201 }}>
      <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        
        {/* Left section: Logo and Mobile Menu (Hamburger Icon) */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={Logo} alt="Logo" style={{ width: '100px', marginLeft: 20 }} />
          {isMobile && (
            <IconButton edge="start" sx={{ ml: 2 }} onClick={toggleSidebar}>
              <MenuIcon sx={{ color: '#7E7E7E' }} />
            </IconButton>
          )}
        </Box>

        {/* Right section: USDT Balance, Wallet Button, and Avatar */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
        }}>
          {isConnected && usdtBalance !== null && (
            <Typography sx={{ color: '#43a047', fontSize: isMobile ? '0.8rem' : '1rem' }}>
              USDT Balance: {usdtBalance}
            </Typography>
          )}
          {isMobile ? <></> : <Button
            variant="contained"
            sx={{
              backgroundColor: isConnected ? '#43a047' : '#1976d2',
              color: '#fff',
              borderRadius: '30px',
              padding: '5px 15px',
              fontSize: isMobile ? '0.8rem' : '1rem',
            }}
            onClick={handleConnect1}
          >
            {isConnected ? `Connected: ${account?.slice(0, 6)}...${account?.slice(-4)}` : 'Connect Wallet'}
          </Button>}

          <IconButton sx={{ padding: isMobile ? '10px' : '0' }}>
            <Avatar src="https://i.pravatar.cc/300" sx={{ width: isMobile ? 40 : 50, height: isMobile ? 40 : 50 }} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;